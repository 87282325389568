import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Footer from '../components/Layout/footer';
import SEOHeader from '../components/Head';

const Books: React.FC = () => (
    <>
        <SEOHeader title="Pierre Buzulier - Books" />
        <main className="container mx-auto p-6 min-h-screen relative pb-24">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 lg:gap-32 py-12 lg:py-24 lg:px-36">
                <div className="flex flex-col gap-8 text-center font-serif justify-between">
                    <StaticImage src="../images/books/pommecul1.png" alt="pommecul vol.1" />
                    <p className="px-4 leading-5 text-lg">
                        <strong className="">pommecul vol.1</strong>
                        <br />
                        la vie d'un directeur artistique en agence de publicité
                    </p>
                    <a
                        href="https://pyramyd-editions.com/products/pommecul"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="font-sans underline"
                    >
                        en savoir plus
                    </a>
                </div>
                <div className="flex flex-col gap-8 text-center font-serif justify-between">
                    <StaticImage src="../images/books/pommecul2.png" alt="pommecul vol.2" />
                    <p className="px-4 leading-5 text-lg">
                        <strong className="">pommecul vol.2</strong>
                        <br />
                        il est free, il a tout compris
                    </p>
                    <a
                        href="https://pyramyd-editions.com/products/pommecul-2"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="font-sans underline"
                    >
                        en savoir plus
                    </a>
                </div>
                <div className="flex flex-col gap-8 text-center font-serif justify-between">
                    <StaticImage src="../images/books/brief.png" alt="On se fait un grief?" />
                    <p className="px-4 leading-5 text-lg">
                        <strong className="">on se fait un brief ?</strong>
                        <br />
                        l'art de bien briefer
                    </p>
                    <a
                        href="https://pyramyd-editions.com/products/on-se-fait-un-brief"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="font-sans underline"
                    >
                        en savoir plus
                    </a>
                </div>
            </div>
            <div className="text-center">
                <StaticImage src="../images/books/logo-pyramyd.png" alt="Pyramyd editions" />
            </div>
        </main>
        <Footer />
    </>
);

export default Books;
